import React, { Component, Suspense } from 'react';
import { HashRouter, Route, Switch, Redirect } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import './App.scss';
import 'antd/dist/antd.css';
import axios from "axios"
import '@react-pdf-viewer/core/lib/styles/index.css';
import cookie from 'react-cookies';
import Maintenance from './views/Maintenance/Maintenance.js';

const history = createBrowserHistory();
const loading = () => <div className="animated fadeIn pt-3 text-center"><div className="sk-spinner sk-spinner-pulse"></div></div>;

// Containers
const DefaultLayout = React.lazy(() => import('./containers/DefaultLayout'));
const Login = React.lazy(() => import('./views/Login/Login'));
const Forgot = React.lazy(() => import('./views/ForgotPassword/ForgotPassword.js'));
const RequestForgotPassword = React.lazy(() => import('./views/RequestForgotPassword'));

const isAuthenticated = () => {
  if (cookie.load('loginUser')) {
    return true;
  } else {
    return false;
  }
}

const UnauthenticatedRoute = ({ component: Component, ...rest }) => (
  <Route {...rest} render={(props) => (
    !isAuthenticated()
      ? <Component {...props}{...rest} />
      : <Redirect to='/' />
  )} />
);

const AuthenticatedRoute = ({ component: Component, ...rest }) => {
  return (
    <Route {...rest} render={(props) => (
      isAuthenticated()
        ? <Component {...props}{...rest} />
        : <Redirect to='/login' />
    )} />
  );
}

axios.interceptors.response.use(null, function (error) {
  if (error.response.status === 401) {
    cookie.remove('dataUser');
    cookie.remove('dataMenu');
    cookie.remove('loginUser');
    cookie.remove('userToken');
    cookie.remove('refreshToken');
    axios.defaults.headers.common.Authorization = null;
    window.location.reload();
  } else if (error.response.status === 503) {
    history.push("/#/maintenance");
    window.location.reload();
  }
  return Promise.reject(error);
})

class App extends Component {
  render() {
    return (
      <HashRouter history={history}>
        <Suspense fallback={loading()}>
          <Switch>
            <Route path="/maintenance"><Maintenance /></Route>
            <UnauthenticatedRoute exact path="/login/:key" name="Login Page" component={Login} />
            <UnauthenticatedRoute exact path="/login" name="Login Page" component={Login} />
            <UnauthenticatedRoute exact path="/forgot-password" name="Forgot Password Page" component={RequestForgotPassword} />
            <UnauthenticatedRoute exact="true" strict="true" path="/forgetpass/:key" name="Forget Password" component={Forgot} />
            <AuthenticatedRoute path="/" name="Home" component={DefaultLayout} />
          </Switch>
        </Suspense>
      </HashRouter>
    );
  }
}

export default App;
