import React, { useEffect } from 'react';
import logo from '../../assets/img/brand/0.png';
import { useHistory } from 'react-router-dom';
import { hitApiUpMaintenance } from '../../configs/Api';

function Maintenance() {
  const history = useHistory();

  useEffect(() => {
    const interval = setInterval(async () => {
      const response = await hitApiUpMaintenance();
      if (response.status === true) {
        history.push("/dashboard");
        window.location.reload();
      }
    }, 5000);
    return () => clearInterval(interval);
  }, []);

  return (
    <div className='app align-items-center justify-content-center' >
      <img src={logo} alt="loan market logo" className='mb-1' style={{height:"120px", width:"120px"}}/>
      <div className='maintenance-color'>
        <h1 className='mb-1'>503</h1>
      </div>
      <p className='maintenance-text mb-5 text-center'>Under Maintenance. We'll be back soon!</p>
    </div>
  )
}

export default Maintenance