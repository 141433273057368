import config from "./config"
const RestAPI = {
    ExportReportCSVPefindo: `${config.domain}/pefindo/pdf/report/csv`,
    ImportBankProductApi: `${config.domain}/bank-product-import`,
    ExportBankProductApi: `${config.domain}/bank-product-export`,
    ImportBankOfficerApi: `${config.domain}/bank-officer-import`,
    ExportBankOfficerApi: `${config.domain}/bank-officer-export`,
    Login: `${config.domain}/login`,
    LoginRevisiOTP: `${config.domain}/v2/login`,
    LoginVerifyOTP: `${config.domain}/v2/otp`,
    LoginGetDataUser: `${config.domain}/v2/me`,
    RefreshToken: `${config.domain}/v2/refresh-token`,
    ListProductById: `${config.domain}/bank-product/lists-by-product/`,
    GetProfil: `${config.domain}/profile`,
    UpdateProfil: `${config.domain}/profile`,
    GetProductBank: `${config.domain}/bank-product/lists-by-product/`,
    GetDetailProduk: `${config.domain}/bank-product/`,
    UpdateProduk: `${config.domain}/bank-product/`,
    AddProduct: `${config.domain}/bank-product`,
    ListBank: `${config.domain}/bank`,
    DetailBank: `${config.domain}/bank/`,
    AddBank: `${config.domain}/bank`,
    UpdateBank: `${config.domain}/bank/`,
    DeleteBank: `${config.domain}/bank/`,
    CreateCP: `${config.domain}/bank-cp`,
    DeleteCp: `${config.domain}/bank-cp/`,
    CreateBO: `${config.domain}/bank-officer`,
    UpdateBO: `${config.domain}/bank-officer/`,
    EditCabangBank: `${config.domain}/bank-branch/`,
    CreateCabangBank: `${config.domain}/bank-branch`,
    DeleteCabang: `${config.domain}/bank-branch/`,
    DetailCabangBank: `${config.domain}/bank-branch/`,
    BankBranchByBank: `${config.domain}/bank-branch/lists-by-bank/`,
    ListProvince: `${config.domain}/lookup/listsProvince`,
    ListCityByProvince: `${config.domain}/lookup/listsCityByProvince/`,
    SendToAdviser: `${config.domain}/application/set/adviser/`,
    RejectApp: `${config.domain}/application/set/adviser/reject/`,
    ApproveApp: `${config.domain}/application/set/adviser/approve/`,
    ListCpByBranch: `${config.domain}/bank-cp/lists-by-branch/`,
    ListBranchByCityAndBank: `${config.domain}/bank-branch/lists-by/bank-and-city?`,
    ListBoByBranch: `${config.domain}/bank-officer/lists-by-branch/`,
    DetailCp: `${config.domain}/bank-cp/`,
    UpdateCp: `${config.domain}/bank-cp/`,
    DetailBo: `${config.domain}/bank-officer/`,
    ListNoteByContact: `${config.domain}/contact-note/lists-by-contact/`,
    ListAppByContact: `${config.domain}/application/lists-by-contact/`,
    DetailNote: `${config.domain}/contact-note/`,
    CreateNote: `${config.domain}/contact-note`,
    DeleteNote: `${config.domain}/contact-note/`,
    UpdateNote: `${config.domain}/contact-note/`,
    ListContact: `${config.domain}/contact`,
    CreateContact: `${config.domain}/contact`,
    UpdateContact: `${config.domain}/contact/`,
    DeleteContact: `${config.domain}/contact/`,
    RestoreContact: `${config.domain}/contact/restore/`,
    ListContactType: `${config.domain}/lookup/listsContactType`,
    ListJobType: `${config.domain}/lookup/listsJobType`,
    ListCategory: `${config.domain}/contact-category`,
    DetailContact: `${config.domain}/contact/`,
    ListContactByAdviser: `${config.domain}/contact/lists-by-adviser/`,
    ListContactOtherByAdviser: `${config.domain}/contact/other-by-adviser/`,
    ListContactActive: `${config.domain}/contact/lists/active`,
    ListContactDeactive: `${config.domain}/contact/lists/deactive`,
    ListContactByOffice: `${config.domain}/contact/lists-by-office/`,
    ListAccessContact: `${config.domain}/contact-access/lists-by-contact/`,
    UnAccessContact: `${config.domain}/contact-access/unlisted-by-contact/`,
    DeleteAccess: `${config.domain}/contact-access/`,
    CreateAccess: `${config.domain}/contact-access`,
    ListAppByAdviser: `${config.domain}/application/lists-by-adviser/`,
    ListAppByPrincipal: `${config.domain}/application/lists-by-principal/`,
    DetailApp: `${config.domain}/application/`,
    DeleteApp: `${config.domain}/application/`,
    GetMenuDashboard: `${config.domain}/lookup/dashboard`,
    Logout: `${config.domain}/logout`,
    GetOffice: `${config.domain}/office`,
    ChangePassword: `${config.domain}/changepassword`,
    SetTarget: `${config.domain}/adviser/set-target/`,
    ListAdviser: `${config.domain}/adviser`,
    ListAdviserByOffice: `${config.domain}/adviser/by/office/`,
    ListAdviserByOfficeAdmin: `${config.domain}/adviser/by/office/admin/`,
    ListAdviserByCsm: `${config.domain}/adviser/by/office/admin/`,
    DetailProspectBank: `${config.domain}/application/detail/bank/prospect/`,
    ProcessToBank: `${config.domain}/application/proses-to-bank/`,
    ProcessToOneBank: `${config.domain}/application/proses-to-bank/`,
    ListAppByBO: `${config.domain}/application/lists-by-bo/`,
    ListAppByAdmin: `${config.domain}/application/lists/by/admin`,
    ListOfficeForAdmin: `${config.domain}/application/lists/advisor/prospect`,
    ListOffice: `${config.domain}/office`,
    ListCabangBankByBank: `${config.domain}/bank-branch/lists-by-bank/`,
    ListProv: `${config.domain}/lookup/listsProvince`,
    ListBOByBranch: `${config.domain}/bank-officer/lists-by-branch/`,
    ListAppByProduct: `${config.domain}/application/lists/by/product/`,
    Reference: `${config.domain}/lookup/reference`,
    ListAppByOffice: `${config.domain}/application/lists/by/office/`,
    CreateApp: `${config.domain}/application`,
    UpdateApp: `${config.domain}/application/`,
    EmailOneBO: `${config.domain}/application/proses-to-bank/`,
    CityByProvince: `${config.domain}/lookup/listsCityByProvince/`,
    DistrictByCity: `${config.domain}/lookup/listsDistrictByCity/`,
    SuburbByDistrict: `${config.domain}/lookup/listsSuburbByDistrict/`,
    GetWilayahByBank: `${config.domain}/bank-branch/lists-city-branch/`,
    AccessToClient: `${config.domain}/application/aktif-akses-nasabah/`,
    UploadDocs: `${config.domain}/application/upload-doc`,
    DeleteDocs: `${config.domain}/application/delete-doc/`,
    CPA: `${config.domain}/application/`,
    DeleteBankProduct: `${config.domain}/application/delete-bank-product/`,
    DeleteProduct: `${config.domain}/bank-product/`,
    ListStepApp: `${config.domain}/loan-step/lists-by-app/`,
    DetailStepLoan: `${config.domain}/loan-step/`,
    UpdateStepLoan: `${config.domain}/loan-step/`,
    CreateStepLoan: `${config.domain}/loan-step`,
    GetStatusSelect: `${config.domain}/loan-step/lists-for-bo/`,
    AdvisorByOfficeOnAdd: `${config.domain}/user/lists`,
    AdvisorByOffice: `${config.domain}/user/management`,
    UserManagement: `${config.domain}/user/management`,
    CreateUser: `${config.domain}/user/management`,
    DetailAdviserManagement: `${config.domain}/user/management/`,
    ListRole: `${config.domain}/user/lists/role`,
    Deactive: `${config.domain}/user/management/deactive/`,
    Active: `${config.domain}/user/management/active/`,
    ForgetPassword: `${config.domain}/forgetpassword`,
    ResetPassword: `${config.domain}/newpassword`,
    Notification: `${config.domain}/lookup/notification/`,
    DeleteNotification: `${config.domain}/lookup/notification/`,
    DeleteNotificationAll: `${config.domain}/lookup/notification/`,
    PostTest: `${config.domain}crypt/`,
    ListDocById: `${config.domain}/contact/list-contact-doc`,
    FilterDetailBank: `${config.domain}/bank-branch/lists-prov-branch/`,
    CreateAdminBank: `${config.domain}/bank-admin`,
    AdminBankByBank: `${config.domain}/bank-admin/lists-by-bank/`,
    DetailAdminBank: `${config.domain}/bank-admin/`,
    updateAdminBank: `${config.domain}/bank-admin/`,
    DeleteAdminBank: `${config.domain}/bank-admin/`,
    SearchCreditScoreCompany: `${config.domain}/pefindo/search-company`,
    SearchCreditScoreIndividual: `${config.domain}/pefindo/search-individual`,
    ReportCreditScore: `${config.domain}/pefindo/report`,
    PdfReport: `${config.domain}/pefindo/pdf/report`,
    HistoryCreditScore: `${config.domain}/pefindo/list-record?`,
    ListSubscriber: `${config.domain}/subscribe/pefindo`,
    UpdateSubscriber: `${config.domain}/subscribe/pefindo/`,
    ContactByAdviser: `${config.domain}/contact/all-by-adviser/`,
    RequestCreditScore: `${config.domain}/request/pefindo`,
    ListRequest: `${config.domain}/request/pefindo?`,
    DeleteRequest: `${config.domain}/request/pefindo/`,
    ListRequestByOffice: `${config.domain}/request/pefindo?`,
    DetailCreditScoreDB: `${config.domain}/pefindo/report/from-db`,
    PdfCreditScoreDB: `${config.domain}/pefindo/pdf/report/from-db`,
    CreateInvoice: `${config.domain}/invoice/pefindo`,
    ListInvoice: `${config.domain}/invoice/pefindo?`,
    ListHistoryByAdmin: `${config.domain}/request/pefindo/history-adviser?`,
    UpdateInvoice: `${config.domain}/invoice/pefindo/`,
    UpdateInvoiceSlip: `${config.domain}/invoice/pefindo/`,
    ContactPartner: `${config.domain}/contact-partner`,
    CategoryPartner: `${config.domain}/contact-partner/lists/category-partner`,
    ListContactPartner: `${config.domain}/contact-partner/lists/contact-admin-branch/`,
    postAdviserContact: `${config.domain}/contact-partner/set/adviser/`,
    ListLoanByPartner: `${config.domain}/application/lists-by-partner/`,
    AddContactAll: `${config.domain}/contact-partner/set/more/adviser`,
    ListsDeveloper: `${config.domain}/product-developer/lists/developer`,
    ListsDeveloperNew: `${config.domain}/v2/application/developer`,
    ListProductKPR: `${config.domain}/product-developer/lists/product`,
    ListProjectByDeveloper: `${config.domain}/project-developer/lists-by-developer/`,
    ListProjectByDeveloperNew: `${config.domain}/v2/application/project-developer`,
    CreateProjectDev: `${config.domain}/project-developer`,
    updateProject: `${config.domain}/project-developer`,
    ProductDeveloper: `${config.domain}/product-developer`,
    DeleteProject: `${config.domain}/project-developer/delete/`,
    DeleteProductDeveloper: `${config.domain}/product-developer/`,
    ListProductByDev: `${config.domain}/product-developer/lists/product/by-developer/`,
    ListPropertyByProjectNew: `${config.domain}/v2/application/product-developer`,
    LoanByDeveloper: `${config.domain}/application/lists-by-developer/`,
    ListPoolByBO: `${config.domain}/application/lists/inquired-bankofficer`,
    PdfCPAPool: `${config.domain}/application/createcpa/inquired-bankofficer/`,
    RequestData: `${config.domain}/application/proses/inquired-bankofficer/`,
    ListStatbyAdminOff: `${config.domain}/application/lists-by-admin-office/`,
    ListFaq: `${config.domain}/faq`,
    ListPipeline: `${config.domain}/pipeline`,
    UpdatePresetase: `${config.domain}/presentase`,
    Referral: `${config.domain}/referral`,
    Transaksi: `${config.domain}/transaksi`,
    LmsRekening: `${config.domain}/rekening-lms`,
    InvoiceLMS: `${config.domain}/invoices-lms`,
    ListContactWebsite: `${config.domain}/contact-listByWebsite`,
    TopFiveBank: `${config.domain}/dashboard/top-five-interest/`,
    TopFiveBankApprove: `${config.domain}/dashboard/top-five-approve`,
    ListPostalCodeApi: `${config.domain}/lookup/listpostalcode`,
    ProductCategoryParentApi: `${config.domain}/product-category-parent`,

    ListProduct: `${config.domain}/product`,
    ProductGetCategoryParent: `${config.domain}/product-get-category-parent`,
    ProductGetCategory: `${config.domain}/product-get-category`,
    ProductGetType: `${config.domain}/product-get-type`,
    
    ListBankNonPks: `${config.domain}/bank-non-pks`,
    DetailBankNonPks: `${config.domain}/bank-non-pks/`,
    AddBankNonPks: `${config.domain}/bank-non-pks`,

    GetProductBankNonPks: `${config.domain}/bank-product-non-pks/lists-by-product/`,
    AddProductNonPks: `${config.domain}/bank-product-non-pks`,
    DeleteProductNonPks: `${config.domain}/bank-product-non-pks/`,
    UpdateProdukNonPks: `${config.domain}/bank-product-non-pks/`,
    ListProductNonPksById: `${config.domain}/bank-product-non-pks/lists-by-product/`,

    BankReview: `${config.domain}/review`,
    GetReview: `${config.domain}/check-review/`,
    DeleteReview: `${config.domain}/delete-review/`,

    TopBankOfMonth: `${config.domain}/dashboard/top-approve-month`,
    TopBankOfResponse: `${config.domain}/dashboard/top-bank-response`,
    
    BusinessType: `${config.domain}/contact-business-type`,

    DownloadDocs: `${config.domain}/application/download-doc/`,

    TransferContact: `${config.domain}/contact-transfer-contact`,
    ListContactTransfer: `${config.domain}/contact-lists-transfer-contact/`,

    DownloadFile: `${config.domain}/download/`,

    ContactCategory: `${config.domain}/contact-category`,

    MarketingTool: `${config.domain}/marketing-tool`,
    DownloadKtpNpwp: `${config.domain}/request/pefindo/file/`,
    DownloadDocument: `${config.domain}/download-document/`,

    SelectAdviserPefindo: `${config.domain}/pefindo/select-adviser`,
    GetBankProductPreferences: `${config.domain}/bank-product-preferences`,

    AplicationDocs: `${config.domain}/v2/application`,
    ProcessToOneBankNew: `${config.domain}/v2/application/`,
    Maintenance: `${config.domain}/up`,
    NotificationNew: `${config.domain}/v2/notifications`,
    Ticket: `${config.ticketing}/tickets`,
    TicketCategories: `${config.ticketing}/categories`,

    ProductDeveloperNew: `${config.domain}/v2/product-developers`,
    ProjectDeveloperNew: `${config.domain}/v2/project-developers`,
    FilterDeveloper: `${config.domain}/v2/product-developers/developers`,
    FilterProject: `${config.domain}/v2/product-developers/projects`,
}

export default RestAPI